const text = require('./text.yaml')[templateVars.lang];
import config from './config.yaml';
import platform from 'platform';
import './arrow.js';

async function getTable2ExcelModule() {
	const table2excel = await import('../../table2excelModule.js');

	return table2excel;
}

/**
 * Метод возвращает массив всех графиков со страницы,
 * у которых не задан заголовок (через figcaption).
 * @returns {Array}
 */
function getChartsWithoutTitle() {
	//Взять все таблицы на странице
	let $graphics = $('.graphic');
	//Обойти циклом
	return $graphics.filter((index, graphic) => {
		let $graphic = $(graphic);
		//Проверить наличие caption
		if ($graphic.has('caption').length) {
			//Если есть - пропустить элемент
			return false;
		}

		//Проверить наличие figcaption
		if ($graphic.has('figcaption').length) {
			//Если есть - пропустить элемент
			return false;
		}

		return true;
	}).toArray();
}

/**
 * Метод возвращает массив всех таблиц со страницы,
 * у которых не задан заголовок (через figcaption).
 * @returns {Array}
 */
function getTablesWithoutTitle() {
	//Взять все таблицы на странице
	let $tables = $('.table');
	//Обойти циклом
	return $tables.filter((index, table) => {
		let $table = $(table);
		//Проверить наличие caption
		if ($table.has('caption').length) {
			//Если есть - пропустить элемент
			return false;
		}

		//Проверить наличие figcaption
		if ($table.has('figcaption').length) {
			//Если есть - пропустить элемент
			return false;
		}

		return true;
	}).toArray();
}

/**
 * Метод генерирует имя для переданной элемента. Элементом может быть таблица или график.
 * Алгоритм создания имени:
 * — если у элемента нет заголовка, название файла подтягивает название страницы
 * — если на странице присутствуют несколько элементов без заголовков, то названия
 * будут строиться таким образом — [название страницы]_01 или 02 и т.д.xls
 *
 * @param element - элемент, для которого генерируется имя
 * @param emptyElements - массив всех подобных элементов на странице
 * @returns {boolean}
 */
function getDefaultElementName(element, emptyElements) {
	let result = false;

	//Если пришла пустая выборка
	if (!emptyElements.length) {
		return false;
	}

	//Если пришел один элемент
	if (emptyElements.length == 1) {
		//Присваиваем в качестве имени файла заголовок страницы
		result = templateVars.page.title;
	} else if (emptyElements.length > 1) { //если количество элементов больше  одного
		let countNum = emptyElements.indexOf(element) + 1;
		// 	Если удалось определить номер
		if (countNum) {
			countNum = countNum.pad();
			//Присваиваем имя, сгенерированное по типу [название страницы]_[порядковый номер]
			result = `${templateVars.page.title}_${countNum}`;
		} else {
			//Если нет - возвращаем false
			return false;
		}
	}

	//Возвращаем результат
	return result;
}

/**
 * Получение html-разметки из интерактивного графика
 * @param $container
 * @param $chart
 * @returns {{title, html: *}}
 */
function getChartData($container, $chart) {
	let chartData = global.zebraCharts.getChartAsTableHtml($chart.attr('id'));
	let $caption = $container.find('figcaption').clone();
	let title = '';

	if ($caption.length) {
		removeTooltips($caption);
		title = $caption.text();
	} else {
		title = chartData.title;
	}

	return {
		title: title.trim(),
		html: chartData.html
	};
}

/**
 * Получить последний уровень ссылки
 * @param {string} url ссылка на страницу
 */
function getLastLevelFromUrl(url) {
	return url.match(/\/([^\/]+)(?=\/$|$)/).pop();
}

/**
 * Получить ссылку на xls (for ios)
 * @param {*} index индекс таблиц
 * @param {*} isChart элемент с index является графиком
 */
function getPreparedXlsLink(index, isChart) {
	const configKey = isChart ? 'charts' : 'tables';

	return config.downloadLink[configKey]
		.replace('${site}', templateVars.site)
		.replace('${lang}', templateVars.lang)
		.replace('${pathTail}', templateVars.siteUrl.pathTail)
		.replace('${namePrefix}', config.namePrefix[configKey])
		.replace('${xlsName}', `${getLastLevelFromUrl(templateVars.siteUrl.pathname)}_${index + 1}`);
}

/**
 * Удалить тултип с элемента
 * @param {object} $elem тултип
 */
function removeTooltips($elem) {
	$elem
		.find('.b-tooltip__text, .b-tooltip-number')
		.remove();
}

/**
 * Добавить кнопку скачивания Xls
 */
export default function addExcelExport($elements, noHideInZoom, showOnlyInMobile = false) {
	return $elements.each(function (index) {
		let $this = $(this);
		let $table = $this.find('table');
		let $chart = $this.find('.chart');
		const isIos = platform.os.family == 'iOS';

		let buttonTitle = text.title;
		let noHideInZoom = config.noHideInZoom ? 'data-no-hide-in-zoom="true"' : '';
		let $button;

		let hiddenTitle = '';
		var $currentTable = $table.parents('.b-table');
		var $currentTableFigcaption = $table.siblings('figcaption');

		if ($currentTableFigcaption.length) {
			hiddenTitle = `<span class="hidden">${$currentTableFigcaption.text().trim()}</span>`;
		} else if ($currentTable.parents().parent('.content-area').length) {
			hiddenTitle = `<span class="hidden">${$currentTable.parents().prevAll(':header:first').text().trim()}</span>`;
		} else {
			hiddenTitle = `<span class="hidden">${$currentTable.prevAll(':header:first').text().trim()}</span>`;
		}

		if (isIos) {
			$button = $(`<a href="${getPreparedXlsLink(index, $chart.length)}" class="button button--excel js-button-excel ${(showOnlyInMobile) ? 'button--show-only-in-mobile' : ''}" download target="_blank" ${noHideInZoom}>${buttonTitle}${hiddenTitle}</a>`);
		} else {
			$button = $(`<button class="button button--excel js-button-excel ${(showOnlyInMobile) ? 'button--show-only-in-mobile' : ''}" title="${buttonTitle}" ${noHideInZoom}>${buttonTitle}${hiddenTitle}</button>`);
		}

		if (!$this.find('.figure-buttons.js-buttons').length) {
			$this.append('<div class="figure-buttons js-buttons"></div>');
		}

		AR.events.emit('onButtonHasAdded', [$button, 'excel']);

		$this
			.find('.figure-buttons.js-buttons')
			.append($button);

		if (!isIos) {
			$this
				.find('.js-button-excel')
				.on('click', function excelExport(event) {
					event.preventDefault();

					if ($chart.length) {
						let data = getChartData($this, $chart);
						let $chartHtml = $(data.html);
						let fileName = (data.title) ? data.title : false;

						//этот заголовок потом спарсится в заголовок над таблицей в xlsx
						$chartHtml.append(`<caption>${data.title}</caption>`);

						getTable2ExcelModule()
							.then(table2excelModule => {
								if (!fileName) {
									// Получаем список всех таблиц без заголовков на странице через метод getTablesWithoutTitle()
									let emptyCharts = getChartsWithoutTitle();
									fileName = getDefaultElementName($this[0], emptyCharts);
								}

								//загружаем сгенеренную табличку в DOM, что бы взять у нее адекватные размеры
								let $graphicDOMWrapper = $('<div/>');
								$graphicDOMWrapper.css({
									position: 'fixed',
									left: '-9999px',
									width: '1024px'
								});
								$chartHtml.width('100%');
								$graphicDOMWrapper.append($chartHtml);
								$('body').append($graphicDOMWrapper);

								table2excelModule.table2excelObject.table2excel.export($chartHtml, table2excelModule.table2excelObject.titleToFilename(fileName), function () {
									$graphicDOMWrapper.remove();
									Arrow.show(5000);
								});
							});
					} else if ($table.length) {
						const $clonedTable = $this.clone();
						const $targetTable = $clonedTable.find('table').filter(function (index) {
							return !$(this).parents('.js-fixed-head').length;
						});

						let tempContainer = null;
						let fileName = false;
						let $caption = $targetTable.find('caption').clone();
						let $fgcaption = $targetTable.siblings('figcaption').clone();
						let $h1 = $('h1').first();
						let title = document.title;

						const contentArea = document.querySelector('.content-area');
						const setTargetTable = ($table) => {
							$table.children('.js-fixed-head').remove();

							if (contentArea) {
								tempContainer = document.createElement('div');

								tempContainer.style.overflow = 'hidden';
								tempContainer.style.height = '0px';

								$($table[0]).appendTo(tempContainer);
								$(tempContainer).appendTo(contentArea);
							}
						};

						setTargetTable($clonedTable);

						if ($clonedTable.is('[class*="b-table--scale-"]')) {
							$clonedTable.removeClass(function (index, className) {
								return (className.match(/(^|\s)b-table--scale-\d/)).join(' ');
							});
						}

						if ($caption.length && $caption.text().trim().length) {
							removeTooltips($caption);
							fileName = $caption.text().trim();
						} else if ($fgcaption.length && $fgcaption.text().trim().length) {
							removeTooltips($fgcaption);
							fileName = $fgcaption.text().trim();
						} else if ($h1.length && $h1.text().trim().length) {
							removeTooltips($h1);
							fileName = $h1.text().trim();
						} else {
							fileName = title;
						}

						getTable2ExcelModule()
							.then(table2excelModule => {
								if (!fileName) {
									// Получаем список всех таблиц без заголовков на странице через метод getTablesWithoutTitle()
									let emptyTables = getTablesWithoutTitle();
									fileName = getDefaultElementName($this[0], emptyTables);
								}
								table2excelModule.table2excelObject.table2excel.export($targetTable, table2excelModule.table2excelObject.titleToFilename(fileName), function () {
									Arrow.show(5000);

									if (tempContainer) {
										contentArea.removeChild(tempContainer);
										tempContainer = null;
									}
								});
							});
					}
				});
		}
	});
}

/**
 * Добавление фиктивных нулей к числу
 * @param size - минимальное количество разрядов
 * @returns {string}
 */
Number.prototype.pad = function (size) {
	let s = String(this);
	while (s.length < (size || 2)) {
		s = '0' + s;
	}
	return s;
};

// global.getTable2ExcelModule = getTable2ExcelModule;

if (typeof templateVars !== 'undefined') {
	global.getChartsWithoutTitle = getChartsWithoutTitle;
	global.getTablesWithoutTitle = getTablesWithoutTitle;
	global.getDefaultElementName = getDefaultElementName;
}
