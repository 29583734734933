/**
 * Класс реализует базовую логику для компонента key-indicator
 */
class CKeyIndicator {
	constructor() {}

	/**
	 * Создать копию анимируемого числа для print версии
	 * @param {object} value элемент с цифрой
	 */
	createCopyForPrint(value) {
		const $value = $(value);
		const valueDisplay = $value.css('display');
		const $printValue = $value
			.clone()
			.removeClass('js-indicator-value')
			.addClass(`b-indicator__value--print b-indicator__value--print-${valueDisplay}`);
		$value.addClass('b-indicator__value--print-cloned');

		$value.after($printValue);
	}
}

module.exports = CKeyIndicator;
