import config from './config.yaml';
import CSlider from '../../index.js';

/**
 * Реализует логику варианта gallery компонента slider
 */
class CSlider_gallery extends CSlider {
	constructor() {
		super();
		this.config = config;

		this.instances = {};
	}

	/**
	 * Инициализация
	 */
	async init() {
		this.slidersCollection = [...document.querySelectorAll(`.js-slider-wrapper--${config.name}`)];

		if (this.slidersCollection.length) {
			this.CSwiper = await this.getSwiper();
			this.Tools = await this.getTools();

			this.slidersCollection.forEach((exemplar, index) => {
				let id = exemplar.getAttribute('data-slider-gallery-id');
				/** не инициализировать слайдер при отсутствии переданного id */
				if (!id) {
					return;
				}

				const config = this.config.hasOwnProperty(id) ? this.config[id].options : {};
				this.initSlider(exemplar, id, config);
			});
		}
	}

	/**
	 * Перепишем базовый метод
	 */
	initSlider(sliderWrapper, name, customConfig = {}) {
		const slider = sliderWrapper.querySelector('.js-slider');

		if (slider) {
			this.Tools.disableTab(slider.querySelectorAll('*'));
			this.instances[name] = new this.CSwiper($(slider), this.getConfig(customConfig));

			if (window.innerWidth <= 798 && this.instances[name].slides.length > 0) {
				[].slice.call(this.instances[name].slides).forEach((item) => {
					item.addEventListener('click', (e) => {
						let sliderItems = [].slice.call(slider.querySelectorAll('.js-slider-item'));

						if (sliderItems.length > 0) {
							sliderItems.forEach((item) => item.classList.remove('touched'));
						}

						e.target.classList.add('touched');
					});
				});
			}
			this.setItemVisible(sliderWrapper);

			this.instances[name].on('touchEnd', () => {
				this.setItemVisible(sliderWrapper);
			});

			this.instances[name].on('observerUpdate', () => {
				this.setItemVisible(sliderWrapper);
			});

			$(window).on('resize', () => {
				this.setItemVisible(sliderWrapper);
			});
		}
	}

	/**
	 * Установка видимым центрального изображения в области галереи
	 */
	setItemVisible(sliderWrapper) {
		if ($(window).width() <= 798) {
			let sliderItems = [].slice.call(sliderWrapper.querySelectorAll('.js-slider-item'));

			if (sliderItems.length > 0) {
				sliderItems.forEach((item) => item.classList.remove('touched'));
				const visibleItems = sliderItems.filter((item) => item.getBoundingClientRect().right > 0 && item.getBoundingClientRect().right <= (sliderWrapper.getBoundingClientRect().right + 100));

				if (visibleItems.length > 0) {
					visibleItems[Math.floor(visibleItems.length / 2)].classList.add('touched');
				}
			}
		}
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const cSlider_gallery = new CSlider_gallery();
	// Вызов метода, инициализирующего все существующие события
	cSlider_gallery.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cSlider_gallery, 'cSlider_gallery');
});
