export default class Video {
	constructor(videoWrapper) {
		this.videoWrapper = videoWrapper;
		this.init();
	}

	init() {
		this.video = this.videoWrapper.querySelector('.js-video');
		this.videoPlayTrigger = this.videoWrapper.querySelector('.js-play');
		this.videoFullscreenTrigger = this.videoWrapper.querySelector('.js-fullscreen');
	}

	// onVideoClickEvent(e) {
	// 	if (this.video) {
	// 		if (this.video.paused == true) {
	// 			this.playVideo();
	// 		} else {
	// 			this.pauseVideo();
	// 		}
	// 	}
	// }

	initVideoPlayer() {
		if (this.video) {
			// $(this.video).on('click', $.proxy(this, 'onVideoClickEvent'));
			// $(this.videoPlayTrigger).on('click', $.proxy(this, 'onVideoClickEvent'));

			$(this.video).on('ended', () => {
				this.rewindVideoToStart();
			});

			if ($(this.videoFullscreenTrigger)) {
				if (!this.video.requestFullscreen && !this.video.mozRequestFullScreen && !this.video.webkitRequestFullscreen) {
					$(this.videoFullscreenTrigger).fadeOut();
				}

				$(this.videoFullscreenTrigger).on('click', () => {
					if (this.video.requestFullscreen) {
						this.video.requestFullscreen();
					} else if (this.video.mozRequestFullScreen) {
						this.video.mozRequestFullScreen(); // Firefox
					} else if (this.video.webkitRequestFullscreen) {
						this.video.webkitRequestFullscreen(); // Chrome and Safari
					}
				});
			}
		}
	}

	goToVideoPosition(t = 0) {
		this.video.currentTime = t;
	}

	rewindVideoToStart() {
		this.goToVideoPosition();
		this.pauseVideo();
	}

	pauseVideo() {
		this.video.pause();
		// $(this.videoPlayTrigger).fadeIn();

		if ($(this.videoFullscreenTrigger)) {
			$(this.videoFullscreenTrigger).fadeOut();
		}
	}

	playVideo() {
		this.video.play();
		// $(this.videoPlayTrigger).fadeOut();

		if ($(this.videoFullscreenTrigger)) {
			$(this.videoFullscreenTrigger).fadeIn();
		}
	}
}
