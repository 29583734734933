module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'menu/horizontal-wide-page' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": [
		"menuTitle",
		"shortTitle",
		"titleById"
	],
	"depthLevel": 3,
	"trackerParamsKeyConfig": "topMenu",
	"hoverable": false,
	"hoverableDelay": 200,
	"fixedMenu": false,
	"columnsNum": 3,
	"columnClass": "col-3 col-md-5 col-xs-12",
	"closeSiblingsOnOpen": false,
	"closeOtherItems": false,
	"animation": {
		"listWrap": {
			"open": {
				"transition": "transition.slideDownIn",
				"duration": 300
			},
			"close": {
				"transition": "transition.slideUpOut",
				"duration": 300
			}
		},
		"main": {
			"open": {
				"transition": "slideDown",
				"duration": 200
			},
			"close": {
				"transition": "slideUp",
				"duration": 200
			}
		},
		"inner": {
			"open": {
				"transition": "slideDown",
				"duration": 200
			},
			"close": {
				"transition": "slideUp",
				"duration": 200
			}
		}
	}
};