/**
 * Класс реализует базовую логику для компонента scrollbar,
 * а именно получение плагина и инициализация всех оберток с классом-триггером.
 * Получение конфигураций для каждой конкретной обертки.
 */
class CScrollbar {
	constructor() {}

	/**
	 * Передать JQ-элементы
	 * @param {$} wrapper цели для инициализации слайдера
	 * @param {object} config кастомный конфиг
	 */
	initScrollbar($wrapper, config = {}) {
		let currentConfig = this.getConfig(config);
		let scrollbars = this.getScrollbar()
			.then(scrollbarPlugin => {
				const overlayScrollbar = scrollbarPlugin($wrapper, currentConfig);

				return overlayScrollbar;
			}); // обработка ошибок загрузки ?

		return scrollbars;
	}

	/**
	 * Получение либы скроллбара
	 */
	async getScrollbar() {
		const lib = await import(/* webpackChunkName: "overlayScrollbars" */ 'overlayscrollbars/js/jquery.overlayScrollbars.min.js');
		return AR.getEsm(lib);
	}

	/**
	 * Получить конечный конфиг кастомного скролла
	 * @param {object} customConfig кастомный конфиг (js or dataset)
	 */
	getConfig(customConfig) {
		const newConfig = AR.tools.rExtend(this.config.options, customConfig);
		return newConfig;
	}

}

module.exports = CScrollbar;
