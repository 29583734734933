module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'mobile-menu/vertical-accordeon' && templateVars.config ?
				templateVars.config :
				{
	"titleParam": [
		"menuTitle",
		"shortTitle",
		"titleById"
	],
	"expandCurrentOnLoad": false,
	"depthLevel": 4,
	"hideInnerOnClose": false,
	"hidePreviousListOnExpand": true,
	"trackerParamsKeyConfig": "topMenu",
	"triggerPosInPanel": {
		"top": 0,
		"right": 0
	},
	"animation": {
		"menuItem": {
			"open": {
				"transition": "slideDown",
				"duration": 200
			},
			"close": {
				"transition": "slideUp",
				"duration": 200
			}
		},
		"panel": {
			"open": {
				"transition": "transition.slideRightBigIn",
				"duration": 250
			},
			"close": {
				"transition": "transition.slideRightBigOut",
				"duration": 250
			}
		}
	},
	"additionalLinks": {
		"ar": {
			"ru": [],
			"en": []
		}
	}
};