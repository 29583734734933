module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'slider/single' && templateVars.config ?
				templateVars.config :
				{
	"name": "single",
	"containerMinHeight": "100vh",
	"options": {
		"direction": "horizontal",
		"speed": 1000,
		"loop": true,
		"effect": "slide",
		"fadeEffect": {
			"crossFade": true
		},
		"pagination": {
			"type": "custom"
		},
		"initialSlide": 0,
		"simulateTouch": false,
		"keyboard": {
			"enabled": true,
			"onlyInViewport": false
		},
		"nested": true,
		"runCallbacksOnInit": false,
		"hashNavigation": true,
		"preventInteractionOnTransition": true,
		"parallax": false
	},
	"pages": [
		{
			"hash": "individual-universes",
			"wideness": "wide"
		},
		{
			"hash": "world-standards",
			"wideness": "wide"
		},
		{
			"hash": "advertising",
			"wideness": "wide"
		},
		{
			"hash": "digital-transformation",
			"wideness": "wide"
		},
		{
			"hash": "information-media",
			"wideness": "wide"
		},
		{
			"hash": "sports-broadcasting",
			"wideness": "wide"
		},
		{
			"hash": "entertainment-content",
			"wideness": "wide"
		}
	]
};