module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'tour/base' && templateVars.config ?
				templateVars.config :
				{
	"skipButton": {
		"show": true,
		"template": "<button class=\"hopscotch-nav-button next tour-skip-button\">${skipButtonText}</button>"
	},
	"tours": {
		"ru": null
	}
};