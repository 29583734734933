/**
 * Удаление классов по маске
 * @param {string} mask
 */
global.jQuery.fn.removeClassWild = function (mask) {
	return this.removeClass(function (index, cls) {
		const re = new RegExp(String(`(${mask}.*?(\\s|$))`), 'g');
		return (cls.match(re) || []).join(' ');
	});
};
