import isElementInView from './isElementInView.js';

/**
 * Проверка нахождения элементов с классом check-viewport в области видимости
 * и провешивание / удаление, вызов соответствующего события
 */
function checkViewport() {
	$('.check-viewport').each(function () {
		if (isElementInView(this)) {
			if (!$(this).hasClass('visible')) {
				$(this).addClass('visible');
				$(this).trigger('show');
			}
		} else {
			if ($(this).hasClass('visible')) {
				$(this).removeClass('visible');
				$(this).trigger('hide');
			}
		}
	});
}

$(document).ready(checkViewport);
$(window).scroll(checkViewport);

module.exports = checkViewport;
