import Emitter from 'component-emitter';
import checkViewport from 'component/core/utils/viewport.js';

//Вызов коллбека при загрузке изображений
import './modules/imageLoad.js';
//Обработка ссылок на странице
import './modules/links.js';
//Удаление классов по маске
import './modules/removeClassWild.js';
//Обработка состояния focus на кнопках
import './modules/buttonsCleanFocus.js';

const getComponentName = (id) => id.charAt(0).toUpperCase() + id.slice(1);
const initAndResolve = async (id, resolve, reject) => {
	AR.sleepComponents[id].loading = true;

	try {
		await AR.sleepComponents[id].init();
		resolve();
	} catch (err) {
		reject(err);
	} finally {
		delete AR.sleepComponents[id];
	}
};

const AR = {
	// Язык страницы (en, ru)
	lang: templateVars.lang,
	// Поддерживаемые фичи
	features: {
		svg: $('html').hasClass('feature-svg')
	},
	// Хранилище компонентов
	components: {},
	// Хранилище инициализаторов компонентов, которые могут потребоваться в других компонентах
	sleepComponents: {},
	// Вспомогательные инструменты / функции
	tools: {
		// Клонирование объекта без привязки по ссылке
		cloneObject: function (object = {}) {
			return JSON.parse(JSON.stringify(object));
		},
		// Адаптивный ли сайт
		isResponsive: () => Boolean(templateVars.responsive),
		// Поверка на попадание элементов в область просмотра пользователя
		checkViewport: checkViewport,
		// Получить dom элемент или nodelist в виде массива
		getElemsArr: (elements) => {
			if (elements) {
				if (typeof elements !== 'string' && elements.length) {
					return Array.from(elements);
				} else {
					return [elements];
				}
			} else {
				return [];
			}
		},
		// Рекурсивное объединение объектов
		rExtend: function () {
			for (var i = 1; i < arguments.length; i++) {
				for (var key in arguments[i]) {
					if (arguments[i].hasOwnProperty(key)) {
						if (typeof arguments[0][key] === 'object' && typeof arguments[i][key] === 'object') {
							AR.tools.rExtend(arguments[0][key], arguments[i][key]);
						} else {
							arguments[0][key] = arguments[i][key];
						}
					}
				}
			}
			return arguments[0];
		},

	},
	/**
	 * Локализованый текст
	 * @param  {Object} textObj Текст на нескольких языках в формате {ru: 'русский', en: 'english'}
	 * @return {String} Строка на текущем языке
	 */
	local: (textObj) => {
		return textObj[AR.lang];
	},
	/**
	 * Добавление инстаса компонента в глабольное хранилище компонентов
	 * @param component - инстанс компонента
	 * @param id - id компонента
	 */
	pushComponent: (component, id, initable) => {
		const componentName = getComponentName(id);

		if (!AR.components[id]) {
			AR.components[id] = component;

			AR.events.emit(`on${componentName}HasBeenCreated`, component);
		} else {
			if (!templateVars.production) {
				console.error(`Компонент с ID ${id} уже добавлен`);
			}
		}
	},
	/**
	 * Инициализировать компонент или добавить в хранилище ожидания ицнициализации
	 * @param {object, array} els Элементы, по которым определять нужно ли сразу инициализировать компонент
	 * @param {string} id id компонента
	 * @param {object} init функция инициализатор
	 */
	sleepOrRun: (els, id, init) => {
		if ((els instanceof $ && els.length) || (els && els.length == undefined) || (els && els.length)) {
			AR.waitComponents([], () => {
				init(els);
			});
		} else {
			const componentName = getComponentName(id);

			AR.sleepComponents[id] = {
				init,
				loading: false,
			};

			AR.events.emit(`on${componentName}ReadyToInit`);
		}
	},
	/**
	 * Обертка для ожидания подгрузки переданных компонентов.
	 * @param components - список компонентов, например ['cNotification_base', 'cLogo_base']
	 * @param callback - вызывается после подгрузки всех переданных компонентов
	 */
	waitComponents: (components = [], callback) => {
		if (components.length) {
			const componentsPromises = [];

			//обходим массив всех переданных компонентов
			components.forEach((id) => {
				if (AR.sleepComponents[id] && !AR.sleepComponents[id].loading) {
					componentsPromises.push(new Promise(async (resolve, reject) => {
						initAndResolve(id, resolve, reject);
					}));
				} else if (!AR.components[id]) { //если компоненту еще не загружен
					const componentName = getComponentName(id);

					//завязываем промис на событие его загрузки
					componentsPromises.push(new Promise((resolve, reject) => {
						AR.events.on(`on${componentName}ReadyToInit`, () => {
							if (!AR.sleepComponents[id].loading) {
								initAndResolve(id, resolve, reject);
							}
						});

						AR.events.on(`on${componentName}HasBeenCreated`, (component) => {
							resolve();
						});

						AR.events.on(`on${componentName}HasBeenFailed`, (component) => {
							reject();
						});
					}));
				}
			});

			if (componentsPromises.length) {
				//ждем пока загрузятся все необходимые компоненты
				Promise.all(componentsPromises)
					.catch((error) => {
						if (!templateVars.production) {
							console.error(error);
						}
					})
					.finally(() => {
						callback();
					})
					.catch((error) => {
						if (!templateVars.production) {
							console.error(error);
						}
					});
			} else {
				callback();
			}
		} else {
			callback();
		}
	},
	// Интерфейс для работы с событиями
	events: new Emitter,
	/**
	 * Получить модуль с учетом, если импортируется модуль CommonJS
	 * @param {object} module - модуль
	 */
	getEsm(module) {
		if (typeof module.default && module !== 'function') {
			return module.default;
		}
		return module;
	}
};

// AR.events.on('svgRenderedAll', checkViewport);

// Вынос модуля в global
global.AR = AR;
module.exports = AR;
