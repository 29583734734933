const $dc = $('.js-download-center');

if ($dc.length) {
	AR.waitComponents(['cAccordions_base'], () => {
		init($dc);
	});
}

async function init($dc) {
	const {CDownloadCenter_page} = await import(/* webpackChunkName: "CDownloadCenter_page" */ './main.js');
	const cDownloadCenter_page = new CDownloadCenter_page($dc);

	cDownloadCenter_page.init();

	AR.pushComponent(cDownloadCenter_page, 'cDownloadCenter_page');
}
