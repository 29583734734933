const config = require('./config.yaml');
const CPageNav = require('../../index.js');

/**
 * Реализует логику варианта buttons компонента page-nav
 */
class CPageNav_buttons extends CPageNav {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		this.$pagenavButtons = $('.js-pagenav-buttons');
		if (this.$pagenavButtons.length) {
			this.$upBtn = this.$pagenavButtons.find($('.js-scrollup'));
			this.$pagenavLink = this.$pagenavButtons.find($('.js-pagenav-link'));
			this.$fixedArea = $('.js-fixed-area');
			this.scrollTopValue = this.config.scrollTopValue;
			this.toggleShowUpButton();
			$(window).on('scroll', $.proxy(this, 'toggleShowUpButton'));
			this.$upBtn.on('click', $.proxy(this, 'scrollUp'));
			$(window).on('scroll', $.proxy(this, 'setStaticPagenav', false));
			$(window).on('resize', $.proxy(this, 'setStaticPagenav', false));
			// $(document).on('keydown', $.proxy(this, 'checkKeyDown'));
			// $(document).on('touchstart', $.proxy(this, 'removeTouchClass'));
			// this.$upBtn.on('touchstart', $.proxy(this, 'touchStart'));
			// this.$pagenavLink.on('touchstart', $.proxy(this, 'touchStart'));
			this.addHelpKey();

			if ($(window).width() <= 1080) {
				// $(window).on('scroll', $.proxy(this, 'setStaticPagenav', false));
				// $(window).on('resize', $.proxy(this, 'setStaticPagenav', false));

				AR.events.on('onFontSizeChanged', () => {
					this.setStaticPagenav();
				});

				this.setStaticPagenav(true);
			}
		}
	}

	/**
	 * Установить состояние кнопки наверх
	 */
	toggleShowUpButton() {
		if ($(window).scrollTop() < this.scrollTopValue && !this.$upBtn.hasClass('b-pagenav__scrollup--disabled')) {
			this.$upBtn.addClass('b-pagenav__scrollup--disabled');
		} else if ($(window).scrollTop() > this.scrollTopValue && this.$upBtn.hasClass('b-pagenav__scrollup--disabled')) {
			this.$upBtn.removeClass('b-pagenav__scrollup--disabled');
		}
	}

	/**
	 * Открепить (fixed) / прикрепить page-nav
	 * @param {boolean} init вызов метода при инициализации
	 */
	setStaticPagenav(init = false) {
		let footerHeight = $('.l-footer').innerHeight();
		let documentHeight = $(document).height();
		let windowHeight = $(window).height();
		let breakPointScroll = documentHeight - windowHeight - footerHeight;

		//iOS
		if (platform.os.family == 'iOS') {
			breakPointScroll -= 65;
		}

		//Android
		if (platform.os.family == 'Android') {
			breakPointScroll -= 100;
		}

		if ($(window).width() <= 1080 && $(window).scrollTop() >= breakPointScroll && !this.$fixedArea.hasClass('b-now-static-area')) {
			this.$fixedArea.addClass('b-now-static-area');
		} else if ($(window).scrollTop() + this.$pagenavButtons.height() - $('.js-pagenav-buttons').height() < breakPointScroll
		&& this.$fixedArea.hasClass('b-now-static-area')) {
			this.$fixedArea
				.removeClass('b-now-static-area');
		}
	}
}

AR.waitComponents([], () => {
	// Ожидание пока прогрузятся картинки чтоб пагинация не ушла ниже после загрузки страницы
	$(window).on('load', function () {
		const cPageNav_buttons = new CPageNav_buttons();
		// Вызов метода, инициализирующего все существующие события
		cPageNav_buttons.init();
		// Добавление в глобальный объект AR.components
		AR.pushComponent(cPageNav_buttons, 'cPageNav_buttons');
	});
});
