const config = require('./config.yaml');

if (config.createTooltips) {
	AR.waitComponents([], () => {
		init(config);
	});
} else {
	AR.pushComponent({isDummy: true}, 'cFigureButtons_base');
}

async function init(config) {
	const {CFigureButtons_base} = await import(/* webpackChunkName: "CFigureButtons_base" */ './main.js');
	const cFigureButtons_base = new CFigureButtons_base(config);

	cFigureButtons_base.init();

	AR.pushComponent(cFigureButtons_base, 'cFigureButtons_base');
}
