const $element = $('button, .b-checkbox__input');

if ($element.length) {
	document.addEventListener('keydown', tabListener);
}

function tabListener(event) {
	const TAB_KEY = 9;

	if (event.keyCode == TAB_KEY) {
		document.addEventListener('click', clickListener);
		$element.addClass('focus-on');
		window.removeEventListener('keydown', tabListener);
	}
}

function clickListener() {
	$element.removeClass('focus-on');
	document.addEventListener('keydown', tabListener);
	window.removeEventListener('keydown', clickListener);
}
