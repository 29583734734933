const $graphics = $('.graphic');

if ($graphics.length) {
	AR.waitComponents(['cZoom2', 'cFigureButtons_base'], () => {
		init($graphics);
	});
}

async function init($graphics) {
	const {CGraphic_base} = await import(/* webpackChunkName: "CGraphic_base" */ './main.js');
	const cGraphic_base = new CGraphic_base();

	cGraphic_base.init($graphics);

	AR.pushComponent(cGraphic_base, 'cGraphic_base');
}
