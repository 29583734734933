import config from './config.yaml';
import CSlider from '../../index.js';
import Video from './video.js';
import {throttle} from 'throttle-debounce';

/**
 * Реализует логику варианта single компонента slider
 */
class CSlider_single extends CSlider {
	constructor() {
		super();
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		const sliderWrapper = document.querySelector(`.js-slider-wrapper--${config.name}`);
		this.isIE = $('html').hasClass('browser-ie');
		this.header = document.querySelector('header');
		this.pagesVideo = [];
		this.breakpointAnimations = [];
		const videoWrappers = document.querySelectorAll('.js-video-wrapper');

		if (videoWrappers.length > 0) {
			[].slice.call(videoWrappers).forEach((videoEl) => {
				const video = new Video(videoEl);
				video.initVideoPlayer();
				this.pagesVideo.push(video);
			});
		}

		if (sliderWrapper) {
			this.initSlider(sliderWrapper, {
				pagination: {
					el: sliderWrapper.querySelector('.b-slider-pagination'),
					renderCustom(swiper, current, total) {
						return `<div class="b-slider-pagination__digits">
						<span class="b-slider-pagination__item js-current-slide">${current}</span>/
						<span class="b-slider-pagination__item js-total-slide">${total}</span>
					</div>
					<span class="b-slider-pagination__line js-slider-progress"></span>`;
					},
				},
				on: {
					slideChange() {
						sliderWrapper.querySelector('.js-current-slide').textContent = this.realIndex + 1;
					},
				},
			});
		}

		AR.events.on('onInit', () => {
			setTimeout(() => {
				if (this.swiper) {
					this.setNavigationTitle();

					[].slice.call(this.swiper.slides).forEach((slide) => {
						const edgesElement = `<div class="b-slider-item__edges">
						<span></span>
						<span></span>
						<span></span>
						</div>`;
						slide.insertAdjacentHTML('afterbegin', edgesElement);
						slide.insertAdjacentHTML('beforeend', edgesElement);
					});

					const navButtons = [].slice.call(document.querySelectorAll('.js-slider-button'));

					if (navButtons.length > 0) {
						navButtons.forEach(btn => {
							btn.addEventListener('click', (e) => {
								e.preventDefault();

								if (window.pageYOffset > 0) {
									$('html, body').animate({scrollTop: 0}, 500, () => {
										setTimeout(() => {
											if (btn.classList.contains('js-slider-button--prev')) {
												this.swiper.slidePrev();
											} else {
												this.swiper.slideNext();
											}
										}, 700);
									});
								} else {
									if (btn.classList.contains('js-slider-button--prev')) {
										this.swiper.slidePrev();
									} else {
										this.swiper.slideNext();
									}
								}
							});
						});
					}

					this.swiper.on('slideChangeTransitionStart', () => {
						this.pagesVideo.forEach((item) => {
							if (!item.video.paused) {
								item.pauseVideo();
								item.goToVideoPosition(0);
							}
						});
						$('.b-slider-item__edges.animate').removeClass('animate');
						this.setAutoplay('stop');
						$('.js-slider-button').css({opacity: 0});
						$('.js-scroll-page').css({opacity: 0, visibility: 'hidden'});
						this.animatePage(false);

						this.animateSlide();
						this.setNavigationTitle();
					});
					this.swiper.on('slideChangeTransitionEnd', () => {
						this.setAutoplay('start');
					});

					// учитывая направление смены слайда, выставляем анимацию границ
					this.swiper.on('slideNextTransitionStart', () => {
						$('.swiper-slide-active .b-slider-item__edges:first-child').addClass('animate');
					});

					this.swiper.on('slidePrevTransitionStart', () => {
						$('.swiper-slide-active .b-slider-item__edges:last-child').addClass('animate');
					});
					sliderWrapper.querySelector('.js-total-slide').textContent = this.swiper.slides.length - 2;
					sliderWrapper.querySelector('.js-current-slide').textContent = this.swiper.realIndex + 1;

					this.setActiveContent();
					this.animateSlide();
					this.setAutoplay('start');
					this.checkWidth();
					this.initActions();

					// фикс 100vh с интерфейсом браузера
					let vh = document.body.clientHeight;
					document.documentElement.style.setProperty('--vh', `${vh}px`);
				}
				this.animated = false;

				//	Отключить прелоадер
				AR.components.cPreloader_base.disablePreloaderInItem($(this.sliderWrapper));
			});
		});
	}

	setActiveContent() {
		let $active = $('[data-slide-id="' + this.swiper.realIndex + '"].js-slider-item').addClass('js-nactive');

		// поиск контента для активного слайда
		if ($active) {
			$('.js-page').each(function (i, el) {
				$(this)[$(this).data('hash') === $active.data('hash') ? 'addClass' : 'removeClass']('active');
			});
		}
	}

	setSlideTimers() {
		this.animPageTimeout = setTimeout(() => {
			$('html, body').animate({
				scrollTop: $(window).width() <= 798 ? window.innerHeight * 0.1 : parseFloat(this.scrollBreakpoint) * 0.7,
			}, 500);
		}, 5000);

		this.movePageTimeout = setTimeout(() => {
			if (window.pageYOffset > 0) {
				$('html, body').animate({scrollTop: 0}, 500, () => {
					setTimeout(() => {
						this.swiper.slideNext();
					}, 700);
				});
			} else {
				this.swiper.slideNext();
			}
		}, 15000);
	}

	animateSlide() {
		this.setActiveContent();
		let $active = $('.swiper-slide-active[data-slide-id="' + this.swiper.realIndex + '"].js-slider-item').addClass('js-nactive');
		this.setSlideTimers();

		// определение элементов и подготовка анимации
		let $title = $active.find('.b-slider-item__title');
		let $description = $active.find('.b-slider-item__descripion');
		let $pagination = $('.b-slider-pagination');

		$('.js-slider-button').css({opacity: 1});
		$('.js-scroll-page').css({opacity: 1, visibility: 'visible'});

		if ($(window).width() > 798 && $(window).width() < 1201) {
			const leftPosition = document.querySelector('.swiper-slide-active .b-slider-item__content').offsetLeft;
			$('.b-slider-pagination').css({marginLeft: `${leftPosition}px`});
		}
		var mySequence = [];

		if ($title[0]) {
			$title.css({opacity: 0});
			mySequence.push({e: $title, p: {opacity: 1, translateX: [0, '50px']}, o: {duration: 500, delay: 300, sequenceQueue: false}});
		}

		if ($description[0]) {
			$description.css({opacity: 0});
			mySequence.push({e: $description, p: {opacity: 1, translateX: [0, '50px']}, o: {duration: 500, delay: 200, sequenceQueue: false}});
		}

		if ($pagination[0]) {
			$pagination.css({opacity: 0});
			setTimeout(() => {
				this.setPaginationPosition();
			}, 200);
			mySequence.push({e: $pagination, p: {opacity: [1, 0], translateX: [0, '50px']}, o: {duration: 500, delay: (!$description[0] && !$title[0]) ? 600 : 100}});
		}

		$.Velocity.RunSequence(mySequence);
		$active.addClass('animated');
	}

	initActions() {
		// так как пагинация и контент слайдера лежат в разных блоках, сдвигаем через координаты
		$(window).on('resize', () => {
			if ($(window).width() > 798) {
				// фикс 100vh с интерфейсом браузера
				let vh = window.innerHeight;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			}
			this.setPaginationPosition();
			this.checkWidth();
		});

		// анимируем контент при скролле или клике на кнопку
		$('.js-scroll-page').on('click', () => {
			$('html, body').animate({
				scrollTop: $(window).width() <= 798 ? window.innerHeight * 0.1 : parseFloat(this.scrollBreakpoint) * 0.7,
			}, 500);
			this.setAutoplay('stop');
		});
		const throttleFunc = throttle(100, () => {
			this.toggleHeaderColor();
			this.movePageContent();
		});
		$(window).on('scroll', throttleFunc);

		// остановка автоплея при скролле пользователем
		$(document).keydown(function (e) {
			if (e.which == 33 // page up
					|| e.which == 34 // page dn
					|| e.which == 32 // spacebar
					|| e.which == 38 // up
					|| e.which == 40 // down
					|| (e.ctrlKey && e.which == 36) // ctrl + home
					|| (e.ctrlKey && e.which == 35) // ctrl + end
			) {
				this.setAutoplay('stop');
			}
		});

		// Mozilla/Webkit
		if (window.addEventListener) {
			document.addEventListener('DOMMouseScroll', () => this.setAutoplay('stop'), false);
		}

		//for IE/OPERA etc
		document.onmousewheel = () => this.setAutoplay('stop');

		// mobile
		$(window).on('touchmove', () => {
			this.setAutoplay('stop');
		});
	}

	/**
	 *  вызов направления анимации по позиции скролла
	 */
	movePageContent() {
		var wS = $(window).scrollTop();

		if ($(window).width() <= 798) {
			if (this.animated && wS <= this.scrollBreakpoint - 1) {
				this.animatePage(false);
				this.animated = false;
			}
			if (!this.animated && wS >= this.scrollBreakpoint) {
				this.animatePage(true);
				this.animated = true;
			}
		} else {
			this.animatePage(true);
		}
	}

	calcDistanceBetween(firstElem, lastElem, offset = 0) {
		return firstElem.getBoundingClientRect().bottom - lastElem.getBoundingClientRect().top + offset;
	}

	/**
	 *  анимация контента страницы под слайдером
	 * @param {boolean} toUp флаг "всплытия" контента
	 */
	animatePage(toUp) {
		const $slideTitle = $('.swiper-slide-active .js-slide-title, .swiper-slide-active .js-slide-title::before');
		const $slideSubTitle = $('.swiper-slide-active .js-slide-sub-title');
		const $navButtons = $('.l-slider-button-wrapper');
		const $scrollBtn = $('.js-scroll-page');
		const $description = $('.swiper-slide-active .b-slider-item__descripion');
		const $contentPage = $('.js-page.active');
		const $pagination = $('.b-slider-pagination');

		if ($(window).width() <= 798) {
			const mySequence = [
				{e: $navButtons, p: {translateY: toUp ? this.calcDistanceBetween($('header')[0], $navButtons[0], 40) : 0}, o: {sequenceQueue: false, mobileHA: false}},
				{e: $slideTitle, p: {translateY: toUp ? this.calcDistanceBetween($('header')[0], $slideTitle[0], ($slideTitle.height() + 70)) : 0}, o: {sequenceQueue: false}},
				{e: $contentPage, p: {marginTop: toUp ? this.calcDistanceBetween($('header')[0], $contentPage[0], ($slideTitle.height() * 4 + 100)) : 0}, o: {sequenceQueue: false}}
			];

			if ($slideSubTitle[0]) {
				mySequence.push({e: $slideSubTitle, p: {translateY: toUp ? this.calcDistanceBetween($('header')[0], $slideSubTitle[0], ($slideSubTitle.height() + 105)) : 0}, o: {sequenceQueue: false}});
			}

			$.Velocity.RunSequence(mySequence);
			$([$scrollBtn[0], $pagination[0], $navButtons[0]])[toUp ? 'addClass' : 'removeClass']('scrolled');

			if ($description[0]) {
				$($description[0])[toUp ? 'addClass' : 'removeClass']('scrolled');
			}
		} else {
			setTimeout(() => {
				if ($(window).scrollTop() > this.breakpointAnimations[this.breakpointAnimations.length - 1].position.end) {
					return;
				}

				this.breakpointAnimations.map((item) => {
					const condition = $(window).scrollTop() > item.position.start && $(window).scrollTop() <= item.position.end;
					$([$slideTitle[0], $navButtons[0], $scrollBtn[0], $pagination[0]])[condition ? 'addClass' : 'removeClass'](item.class);

					if ($description[0]) {
						$description[condition ? 'addClass' : 'removeClass'](item.class);
					}

					if ($slideSubTitle[0]) {
						$slideSubTitle[condition ? 'addClass' : 'removeClass'](item.class);
					}
				});
			}, 500);
		}

		if ($(window).scrollTop() > 0) {
			$scrollBtn.css({opacity: 0, visibility: 'hidden'});
		} else {
			$scrollBtn.css({opacity: 1, visibility: 'visible'});
		}
	}

	/**
	 *  выставляет брейкпоинт, по которому требуется анимировать контент
	 */
	checkWidth() {
		const windowsize = $(window).width();

		if (windowsize <= 798) {
			this.scrollBreakpoint = 10;
		} else {
			this.scrollBreakpoint = window.innerHeight;
		}

		this.breakpointAnimations.push(
			{
				position: {start: 0, end: parseFloat(this.scrollBreakpoint) * 0.4},
				class: 'scrolled-30'
			},
			{
				position: {start: parseFloat(this.scrollBreakpoint) * 0.4, end: parseFloat(this.scrollBreakpoint) * 1},
				class: 'scrolled-60'
			}
		);
	}

	setPaginationPosition() {
		if ($('.b-slider-pagination')) {
			if ($(window).width() > 798) {
				let $content = $('.swiper-slide-active').find('.b-slider-item__content');
				if ($content[0]) {
					$('.b-slider-pagination').css({marginTop: `${$content[0].getBoundingClientRect().bottom}px`});
				}
			} else {
				$('.b-slider-pagination').css({marginTop: 0});
			}
		}
	}

	setAutoplay(action) {
		$('.js-slider-progress')[action === 'start' ? 'addClass' : 'removeClass']('start');

		if (action === 'stop') {
			if (this.animPageTimeout) {
				window.clearTimeout(this.animPageTimeout);
			}

			if (this.movePageTimeout) {
				window.clearTimeout(this.movePageTimeout);
			}
		}
	}

	/**
	 *  изменение фонового цвета шапки рядом с контентом страницы
	 */
	toggleHeaderColor() {
		if (window.scrollY + $(this.header).outerHeight() >= $('.js-page.active').offset().top) {
			this.header.classList.add('l-header--color');
		} else {
			this.header.classList.remove('l-header--color');
		}
	}

	setNavigationTitle() {
		let nextBtn = document.querySelector('.js-slider-button--next');
		let prevBtn = document.querySelector('.js-slider-button--prev');

		if (nextBtn) {
			nextBtn.querySelector('.b-slider-button__text').textContent = this.swiper.slides[this.swiper.realIndex + 2].querySelector('.js-slide-title').textContent;
		}

		if (prevBtn) {
			prevBtn.querySelector('.b-slider-button__text').textContent = this.swiper.slides[this.swiper.realIndex].querySelector('.js-slide-title').textContent;
		}
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const cSlider_single = new CSlider_single();
	// Вызов метода, инициализирующего все существующие события
	cSlider_single.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cSlider_single, 'cSlider_single');
});
