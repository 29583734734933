const componentId = 'cTracking_base';
const propertiesNames = { //карта имен свойств
	//имя атрибута, в котором хранится тип отслеживаемого события
	type: 'data-tracker-event-type',
	//имя атрибута, в котором хранится ID события для связи в конфиге
	id: 'data-tracker-event-id',
};
const $trackItems = $(`[${propertiesNames.type}]`);

async function init($trackItems) {
	const {CTracking_base} = await import(/* webpackChunkName: "CTracking_base" */ './main.js');
	const cTracking_base = new CTracking_base($trackItems, propertiesNames);

	cTracking_base.init();

	AR.pushComponent(cTracking_base, componentId);
}

AR.waitComponents([], () => {
	AR.sleepOrRun($trackItems, componentId, init);
});
