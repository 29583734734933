const $counters = $('.js-indicator--animated');
const componentId = 'cKeyIndicator_animated';

async function init($counters) {
	const {CKeyIndicator_animated} = await import(/* webpackChunkName: "CKeyIndicator_animated" */ './main.js');
	const cKeyIndicator_animated = new CKeyIndicator_animated();

	cKeyIndicator_animated.init($counters);

	AR.pushComponent(cKeyIndicator_animated, componentId);
}

AR.waitComponents([], () => {
	AR.sleepOrRun($counters, componentId, init);
});


