const $analysis = $('#analysis');

async function init($analysis) {
	const {initAnalysisModule, initAnalysis} = await import(/* webpackChunkName: "analysisMain" */ './main.js');

	initAnalysisModule(() => {
		initAnalysis($analysis);
	});
}

if ($analysis.length) {
	AR.waitComponents(['cTour_base', 'cPreloader_base', 'cAccordions_base', 'cTooltips_base'], () => {
		init($analysis);
	});
}
