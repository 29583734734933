const modals = [...document.querySelectorAll('.modal')];
const componentId = 'cModal';

async function init(modals) {
	const {CModal} = await import(/* webpackChunkName: "CModal" */ './main.js');
	const cModal = new CModal(modals);

	AR.pushComponent(cModal, componentId);
}
AR.waitComponents(['cTooltips_base'], () => {
	AR.sleepOrRun(modals, componentId, init);
});
