const $menu = $('.js-left-nav--vertical-expandable');

if ($menu && $menu.length) {
	AR.waitComponents([], () => {
		init($menu);
	});
}

async function init($menu) {
	const {CMenu_verticalExpandable} = await import(/* webpackChunkName: "CMenu_verticalExpandable" */ './main.js');
	const cMenu_verticalExpandable = new CMenu_verticalExpandable();

	cMenu_verticalExpandable.init($menu);

	AR.pushComponent(cMenu_verticalExpandable, 'cMenu_verticalExpandable');
}
