const componentId = 'cZoom2';

async function init() {
	const {CZoom2} = await import(/* webpackChunkName: "CZoom2" */ './main.js');
	const cZoom2 = new CZoom2();

	AR.pushComponent(cZoom2, componentId);
}

AR.waitComponents([], () => {
	AR.sleepOrRun([], componentId, init);
});
