const $contentArea = $('#content-area');

if ($contentArea.length) {
	const $links = $contentArea.find('a[href]:not([class="link--custom"])');

	if ($links.length) {
		$links
			// Фильтруем навигационные ссылки
			.filter(function () {
				return $(this).parents('nav').length === 0;
			})
			.each(function () {
				const $this = $(this);
				const href = $this.attr('href');
				// Если это не хеш, открываем в новом окне
				if (!href.includes('#') && (href.includes('http') || href.includes('https'))) {
					$this.attr('target', '_blank');
				}

				// Если это полный адрес, добавляем модификатор --external
				if (href.includes('http') || href.includes('https')) {
					$this.addClass('link--external');
				}

				// Если это телефон, добавляем модификатор --phone
				if (href.includes('tel:')) {
					$this.addClass('link--phone');
				}
			});
	}
}
