const $menu = $('.js-top-nav--horizontal');

if ($menu && $menu.length) {
	AR.waitComponents([], () => {
		init($menu);
	});
}

async function init($menu) {
	const {CMenu_horizontal} = await import(/* webpackChunkName: "CMenu_horizontal" */ './main.js');
	const cMenu_horizontal = new CMenu_horizontal();

	cMenu_horizontal.init($menu);

	AR.pushComponent(cMenu_horizontal, 'cMenu_horizontal');
}
