module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'tooltips/base' && templateVars.config ?
				templateVars.config :
				{
	"svgIcon": "&#9432;",
	"tooltipster": {
		"animation": "fade",
		"animationDuration": 200,
		"delay": 100,
		"delayTouch": [
			100,
			200
		],
		"interactive": true,
		"contentAsHTML": true,
		"contentCloning": true,
		"theme": [
			"default",
			"tooltipster-tooltips"
		],
		"distance": 6,
		"trigger": "custom",
		"maxWidth": 350,
		"triggerOpen": {
			"mouseenter": true,
			"tap": true
		},
		"triggerClose": {
			"mouseleave": true,
			"click": true,
			"tap": true,
			"scroll": true
		},
		"side": [
			"right",
			"left",
			"bottom",
			"top"
		]
	}
};