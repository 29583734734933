const $tables = $('.b-table');

if ($tables.length) {
	AR.waitComponents(['cZoom2', 'cFigureButtons_base', 'cTooltips_base'], () => {
		init($tables);
	});
}

async function init($tables) {
	const {CTables_base} = await import(/* webpackChunkName: "CTables_base" */ './main.js');
	const cTables_base = new CTables_base();

	cTables_base.init($tables);

	AR.pushComponent(cTables_base, 'cTables_base');
}
