const config = require('./config.yaml');
const $logo = $('.b-logo');

if ($logo.hasClass('js-logo--expandable') && config.expandedWidth) {
	AR.waitComponents([], () => {
		init($logo, config);
	});
}

async function init($logo, config) {
	const {CLogo_base} = await import(/* webpackChunkName: "CLogo_base" */ './main.js');
	const cLogo_base = new CLogo_base($logo, config);

	cLogo_base.init();

	AR.pushComponent(cLogo_base, 'cLogo_base');
}
