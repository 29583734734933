module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'search-form/extendable' && templateVars.config ?
				templateVars.config :
				{
	"searchMessageBottom": true,
	"cleanInputOnBlur": true,
	"speechRecognition": true,
	"closeButton": true,
	"quickSearch": false,
	"animation": {
		"input": {
			"show": {
				"effect": "transition.slideRightIn",
				"duration": 200
			},
			"hide": {
				"effect": "transition.slideRightOut",
				"duration": 200
			}
		},
		"message": {
			"show": {
				"effect": "transition.slideDownIn",
				"duration": 200
			},
			"hide": {
				"effect": "transition.slideUpOut",
				"duration": 200
			}
		},
		"label": {
			"show": {
				"effect": "fadeIn",
				"duration": 200
			},
			"hide": {
				"effect": "fadeOut",
				"duration": 200
			}
		},
		"searchResult": {
			"show": {
				"effect": "transition.slideDownIn",
				"duration": 200
			},
			"hide": {
				"effect": "fadeOut",
				"duration": 0
			}
		}
	}
};